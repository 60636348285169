import React, { Fragment } from "react"

function Page1(props) {
  function getSelectedClass(bool) {
    return bool ? "option-selected" : ""
  }
  return (
    <div className="page1-container">
      <div className="a flex-column">
        <div className="catch-div flex justify-center align-center">
          <p className="margin-0">CATCH</p>
        </div>
        <h2>COVID-19 Assessment Tool for Community Health</h2>
        <p>Self-screening to protect both you and your community</p>
        <p>
          Add your phone number (and country code) to be notified on launch*{" "}
        </p>
        <small className="smallText">*We anticipate launch within days</small>
      </div>
      <div className="b flex-column space-between align-end">
        <div>
          <h3 onClick={() => props.changePage("page2")} className="link">
            What is this?
          </h3>
          <h3 onClick={() => props.changePage("page2")} className="link">
            How does it work?
          </h3>
          <h3 onClick={() => props.changePage("page2")} className="link">
            Who are we?
          </h3>
        </div>
        <div
          className="share-btn flex justify-center align-center"
          onClick={props.toggleSocial}
        >
          <p>Share</p>
        </div>
      </div>
      <div className="c flex-column justify-center">
        <h2>SIGNUP</h2>
        {props.submitted ? (
          <div className="post-submit-container">
            <p className="bold">Registration complete.</p>
            <p>
              Thank you for joining CATCH - next steps will be shared with you
              promptly
            </p>
          </div>
        ) : (
          <Fragment>
            <div className="options">
              <div
                className={
                  "option flex justify-center align-center " +
                  getSelectedClass(props.selection1)
                }
                onClick={() => props.handleSelect("selection1")}
              >
                <p>I am a medical professional</p>
              </div>
              <div
                className={
                  "option flex justify-center align-center " +
                  getSelectedClass(props.selection2)
                }
                onClick={() => {
                  props.toggleSurvey()
                  props.handleSelect("selection2")
                }}
              >
                <p>I want to screen for COVID-19</p>
              </div>
              <div
                className={
                  "option flex justify-center align-center " +
                  getSelectedClass(props.selection3)
                }
                onClick={() => props.handleSelect("selection3")}
              >
                <p>I just want to help</p>
              </div>
            </div>
            <div className="interactive">
              {(props.selection1 || props.selection2 || props.selection3) && ( // remove selection 2 when you have a link
                <div className="country-container">
                  <input
                    name="country_code"
                    className="country-input"
                    onChange={props.handleChange}
                    placeholder="+1"
                  />
                  <input
                    name="phone"
                    onChange={props.handleChange}
                    placeholder="Phone number..."
                  />
                  {props.phone && (
                    <p
                      className="margin-0 submit-p"
                      onClick={props.handleSubmit}
                    >
                      Submit &#10230;
                    </p>
                  )}
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default Page1