
import React, { Component } from "react"
import FormHeading from "./form-heading"

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="modal full-modal center">
        <FormHeading className="text-align-center">
          Enter your Email
        </FormHeading>
        <div className="physician-email-container">
          <input
            className="physician-email"
            name="physicianEmail"
            onChange={this.props.handleChange}
            placeholder="Email..."
          />
        </div>
        <div className="btn-container flex-column space-between">
          <div
            onClick={() => {
              this.props.toggleModal('emailModal')
              this.props.handleSubmit()
            }}
            className="btn-div"
            type="submit"
          >
            <p>Submit</p>
          </div>
          <div
            onClick={() => this.props.toggleModal("emailModal")}
            className="btn-div"
            type="submit"
          >
            <p>Close</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal