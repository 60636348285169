import React, { Component, Fragment } from "react"
import Select from "react-select"

import request from "request"

// Custom Components
import LocationSearchInput from "./LocationSearchInput"
import EmergencyModal from "./EmergencyModal"
import ValidationModal from "./ValidationModal"
import Radio from "./radio"
import h4 from "./form-heading"
import Loader from './Loader'
import StartOverModal from './StartOverModal'

import leftArrow from '../LeftArrow.svg'
import rightArrow from '../RightArrow.svg'

// Data
import formSchema from "../formSchema.js"

// Styles
import "../index.css"

const countryOptions = formSchema.countryNames.map(country => ({
  label: country.text,
  value: country.name,
  name: "country"
}))

const Section = props => {
  const { children, ...rest } = props
  return (
    <div className="section" sx={{ py: [2, null, 3] }} {...rest}>
      {children}
    </div>
  )
}

class Survey extends Component {
  constructor(props) {
    super(props)
    this.myRef2 = React.createRef()
    this.state = {
      myRefArr: formSchema["symptoms3Names"].map(symptom => React.createRef()), // symptoms 3 is longest array
      answers: {
        country: null,
        "symptoms-1": null,
        "symptoms-2": null,
        "symptoms-3": [],
        "exposure-1": null,
        "exposure-3": null,
        "exposure-2": null,
        "flu-shot": null,
        "diseases-1": [],
        "diseases-2": [],
        "diseases-3": [],
        "medications-1": [],
        age: null,
        gender: null
      },
      question: 1, // starts at 1
      emergencyModal: true, // answering yes to first question triggers modal, this is for turning off
      validationModal: false, // If they haven't answered all mandatory questions
      startOverModal: false,
      currentPage: 1
    }
  }

  componentDidMount() {
    this.setState({innerHeight: window.innerHeight})
  }

  invertMap = function(countriesList) {
    var inverse = {}
    for (var i = 0; i < countriesList.length; i++) {
      const country = countriesList[i]
      inverse[country.name] = country.text
    }

    return inverse
  }

  countryForCode = this.invertMap(formSchema.countryNames)

  areMandatoryQuestionsAnswered = responses => {
    const requiredForms = {
      country: responses.country,
      "symptoms-1": responses["symptoms-1"],
      "symptoms-2": responses["symptoms-2"],
      "symptoms-3": responses["symptoms-3"],
      "exposure-1": responses["exposure-1"],
      "exposure-2": responses["exposure-2"],
      "exposure-3": responses["exposure-3"],
      "flu-shot": responses["flu-shot"],
      "diseases-1": responses["diseases-1"],
      "diseases-2": responses["diseases-2"],
      "diseases-3": responses["diseases-3"],
      "medications-1": responses["medications-1"],
      age: responses.age
    }
    const keys = Object.keys(requiredForms)
    return keys.every(
      key => requiredForms[key] && requiredForms[key].length > 0
    )
  }

  displayHospital = hospital => {
    const milesCodes = ["US", "UK", "LR", "MM"] // liberia and myanmar
    var distance = ""
    if (milesCodes.includes(this.state.answers.country)) {
      distance = `${Math.round(hospital.distanceMiles, 1)} Mi`
    } else {
      distance = `${Math.round(hospital.distanceKilometers, 1)} Km`
    }
    return `${distance} - ${hospital.name}, ${hospital.city}, ${hospital.state}, ${hospital.zip}`
  }

  isNoneSelected = array => {
    return this.state.answers[array].some(symptom => symptom.includes("none"))
  }

  areSomeSelected = array => {
    return this.state.answers[array].length > 0 && !this.isNoneSelected(array)
  }

  isInputDisabled = (choice, array) => {
    if (choice.includes("none")) {
      return this.areSomeSelected(array)
    } else {
      return this.isNoneSelected(array)
    }
  }

  toggleModal = modal => {
    this.setState({
      [modal]: !this.state[modal]
    })
  }

  getHospitalForId = hospitalId => {
    if (this.state.hospitals && this.state.hospitals.length > 0) {
      for (const hospital of this.state.hospitals) {
        if (hospital["id"] === hospitalId) {
          return hospital
        }
      }
    }
    return null
  }

  getCountryText = () => {
    if (this.state.answers.country) {
      return this.countryForCode[this.state.answers.country]
    } else {
      return "your country"
    }
  }

  onSelectCountry = event => {
    this.setState({
      countryName: event.label,
      answers: { ...this.state.answers, country: event.value }
    })
  }

  getHospitals = locationData => {
    request.post(
      "https://us-central1-lazarus-covid19.cloudfunctions.net/findHospital",
      {
        json: locationData
      },
      (error, res, body) => {
        if (error) {
          console.error(error)
          return
        }
        if ("data" in body) {
          this.setState({ areHospitalsLoading: false, hospitals: body["data"] })
        }
      }
    )
  }

  updateLocation = locationData => {
    this.setState({ areHospitalsLoading: true, answers: { ...this.state.answers, locationData } })
    this.getHospitals(locationData)
  }

  changeQuestion = direction => {
    document.getElementById('root').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    let question
    if (direction === 'forward') {
      question = this.state.question + 1
    } else if (this.state.question > 1) {
      question = this.state.question - 1
    } else {
      question = this.state.question
    }
    const questionChangePromise = new Promise((resolve, reject) => {
      this.setState({ question })
      resolve()
    })

    questionChangePromise.then(() => {
      this.state.myRefArr.forEach(ref => {
        if (ref && ref.current) {
          if (ref.current.type === "radio") {
            if (this.state.answers[ref.current.name] === ref.current.value) {
              ref.current.checked = true
            }
          } else if (ref.current.type === "checkbox") {
            if (this.state.answers[ref.current.name].includes(ref.current.value)) {
              ref.current.checked = true
            }
          } else if ((ref.current.type === "select-one" || ref.current.type ==="text") && this.state.answers[ref.current.name]) {
            if (ref.current.name === "hospitals" && this.state.hospitalText) {
              ref.current.value = this.state.hospitalText
            } else {
              ref.current.value = this.state.answers[ref.current.name]
            }
          }
        }
      })
    })
  }

  onChangeAnswer = event => {
    this.setState({
      answers: {
        ...this.state.answers,
        [event.target.name]: event.target.value
      }
    })
  }

  onSelectAnswer = event => {
    this.setState({
      answers: { ...this.state.answers, [event.name]: event.value }
    })
  }

  isQuestionAnswered = (question) => {
    return this.state.answers[question] && this.state.answers[question].length > 0
  }

  render() {
    const innerHeight = window.innerHeight
    const parentElement = document.getElementById('root')
    const fontSize = parseFloat(getComputedStyle(parentElement).fontSize)
    const Basic = () => (
      <Fragment>
        <header id="header">
          <div className="flex flex-end">
            {/* <div className="app-catch-div flex justify-center align-center">
              <p className="margin-0">CATCH</p>
            </div> */}
            {/* <div onClick={() => this.setState({startOverModal: true})}>
              <p className="back">Back &#10229;</p>
            </div> */}
          </div>
          <div className="title-container">
            <h2 className="margin-top-bottom-05em">
              COVID Assessment Tool for Community Health
            </h2>
          </div>
        </header>
        <form
          onSubmit={event => {
            event.preventDefault()
            const { answers } = this.state
            if (this.areMandatoryQuestionsAnswered(answers)) {
              const jsonData = JSON.stringify(answers, null, 2)
              this.props.submitUserResponse(answers) // send dict
              this.props.changePage("resultsPage")
            } else {
              this.setState({ validationModal: true })
            }
          }}
          onChange={event => {
            const body = document.getElementsByTagName('body')[0]
            const root = document.getElementById('root')
            body.style.height = `${window.innerHeight}px`
            root.style.height = `${window.innerHeight}px`
            if (event.target.type === "checkbox") {
              if (event.target.checked) {
                // add to list
                this.setState({
                  answers: {
                    ...this.state.answers,
                    [event.target.name]: [
                      ...this.state.answers[event.target.name],
                      event.target.value
                    ]
                  }
                })
              } else {
                // remove from list
                const indexOfValue = this.state.answers[
                  event.target.name
                ].indexOf(event.target.value)
                const arr1 = this.state.answers[event.target.name].slice(
                  0,
                  indexOfValue
                )
                const arr2 = this.state.answers[event.target.name].slice(
                  indexOfValue + 1,
                  this.state.answers[event.target.name].length
                )
                const newArr = arr1.concat(arr2)
                this.setState({
                  answers: {
                    ...this.state.answers,
                    [event.target.name]: newArr
                  }
                })
              }
            } else if (
              event.target.type === "radio" ||
              event.target.type === "text"
            ) {
              // radio buttons and text
              // event.target.focus({ preventScroll: true })
              this.setState({
                answers: {
                  ...this.state.answers,
                  [event.target.name]: event.target.value
                }
              })
            }
          }}
        >
          {this.state.question === 1 &&
          <div className="question">
            <h4>What country are you in?</h4>
            <Select
              name="country"
              options={countryOptions}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected ? "black" : "black"
                })
                // control: base => ({
                //   ...base,
                //   height: '3em',
                // })
              }}
              placeholder={"Type or select..."}
              onChange={this.onSelectCountry}
              value={countryOptions.filter(option => option.label === this.state.countryName)}
              ref={this.state.myRefArr[0]}
            />
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("country") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 2 &&
          <div className="question">
            <Section aria-live="polite" className="radio-btns">
              <h4>
                Are you experiencing any of the following?
              </h4>
              <ul>
                <li>
                  Severe difficulty breathing (e.g. struggling to breathe or
                  speaking in single words)
                </li>
                <li> Severe chest pain </li>
                <li> Having a very hard time waking up </li>
                <li> Losing consciousness </li>
              </ul>
              <div className="flex radio-container">
                <Radio htmlFor="symptoms-1-yes" label="Yes">
                  <input
                    id="symptoms-1-yes"
                    name="symptoms-1"
                    type="radio"
                    value="yes"
                    ref={this.state.myRefArr[0]}
                  />
                </Radio>
                <Radio htmlFor="symptoms-1-no" label="No">
                  <input
                    id="symptoms-1-no"
                    name="symptoms-1"
                    type="radio"
                    value="no"
                    ref={this.state.myRefArr[1]}
                  />
                </Radio>
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("symptoms-1") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 3 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>
                Are you experiencing any of the following?
              </h4>
              <ul>
                <li> Shortness of breath at rest </li>
                <li> Inability to lie down due to difficulty breathing </li>
                <li>
                  Chronic health conditions that you are having difficulty
                  managing because of difficulty breathing
                </li>
              </ul>
              <div className="radio-container">
                <Radio htmlFor="symptoms-2-yes" label="Yes">
                  <input
                    id="symptoms-2-yes"
                    name="symptoms-2"
                    type="radio"
                    value="yes"
                    ref={this.state.myRefArr[0]}
                  />
                </Radio>

                <Radio htmlFor="symptoms-2-no" label="No">
                  <input
                    id="symptoms-2-no"
                    name="symptoms-2"
                    type="radio"
                    value="no"
                    ref={this.state.myRefArr[1]}
                  />
                </Radio>
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("symptoms-2") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 4 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>
                Please select any symptoms you are experiencing:
              </h4>
              <div className="checkbox-container">
                {formSchema["symptoms3Names"].map((symptom, i) => {
                  return (
                    <div className="checkbox-div">
                      <input
                        type="checkbox"
                        key={"symptoms3Names" + i}
                        id={"symptoms3Names" + i}
                        value={symptom.name}
                        name="symptoms-3"
                        disabled={this.isInputDisabled(
                          symptom.name,
                          "symptoms-3"
                        )}
                        ref={this.state.myRefArr[i]}
                      />
                      <label className="inline" htmlFor={"symptoms3Names" + i}>
                        {symptom.text}
                      </label>
                    </div>
                  )
                })}
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("symptoms-3") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 5 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>
              {((this.getCountryText() === "United States")) ? ("Did you develop symptoms within 14 days of travel outside or within the United States?") : ("Did you develop symptoms within 14 days of travel outside of " + this.getCountryText() + "?")}
              </h4>
              <div sx={{ display: "flex" }} className="radio-container">
                <Radio htmlFor="exposure-1-yes" label="Yes">
                  <input
                    id="exposure-1-yes"
                    name="exposure-1"
                    type="radio"
                    value="yes"
                    ref={this.state.myRefArr[0]}
                  />
                </Radio>

                <Radio htmlFor="exposure-1-no" label="No">
                  <input
                    id="exposure-1-no"
                    name="exposure-1"
                    type="radio"
                    value="no"
                    ref={this.state.myRefArr[1]}
                  />
                </Radio>
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("exposure-1") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 6 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>
                Did you provide care or have close contact with a person with
                COVID-19 (probable or confirmed) while they were ill (cough,
                fever, sneezing, or sore throat)?
              </h4>
              <div sx={{ display: "flex" }} className="radio-container">
                <Radio htmlFor="exposure-2-yes" label="Yes">
                  <input
                    id="exposure-2-yes"
                    name="exposure-2"
                    type="radio"
                    value="yes"
                    ref={this.state.myRefArr[0]}
                  />
                </Radio>

                <Radio htmlFor="exposure-2-no" label="No">
                  <input
                    id="exposure-2-no"
                    name="exposure-2"
                    type="radio"
                    value="no"
                    ref={this.state.myRefArr[1]}
                  />
                </Radio>
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("exposure-2") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 7 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>
              {((this.getCountryText() === "United States")) ? ("Did you have close contact with a person who travelled outside or within the United States in the last 14 days who has become ill (cough, fever, sneezing, or sore throat)?")  : ("Did you have close contact with a person who travelled outside of " + this.getCountryText() + " in the last 14 days who has become ill (cough, fever, sneezing, or sore throat)?")}
              </h4>
              <div sx={{ display: "flex" }} className="radio-container">
                <Radio htmlFor="exposure-3-yes" label="Yes">
                  <input
                    id="exposure-3-yes"
                    name="exposure-3"
                    type="radio"
                    value="yes"
                    ref={this.state.myRefArr[0]}
                  />
                </Radio>

                <Radio htmlFor="exposure-3-no" label="No">
                  <input
                    id="exposure-3-no"
                    name="exposure-3"
                    type="radio"
                    value="no"
                    ref={this.state.myRefArr[0]}
                  />
                </Radio>
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("exposure-3") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 8 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>Have you had the seasonal flu shot?</h4>
              <div sx={{ display: "flex" }} className="radio-container">
                <Radio htmlFor="flu-shot-yes" label="Yes">
                  <input
                    id="flu-shot-yes"
                    name="flu-shot"
                    type="radio"
                    value="yes"
                    ref={this.state.myRefArr[0]}
                  />
                </Radio>

                <Radio htmlFor="flu-shot-no" label="No">
                  <input
                    id="flu-shot-no"
                    name="flu-shot"
                    type="radio"
                    value="no"
                    ref={this.state.myRefArr[1]}
                  />
                </Radio>
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("flu-shot") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 9 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>
                Have you had any of the following medical conditions?
              </h4>
              <div className="checkbox-container">
                {formSchema["diseases1Names"].map((disease, i) => {
                  return (
                    <div className="checkbox-div">
                      <input
                        type="checkbox"
                        id={"diseases1Names" + i}
                        key={"diseases1Names" + i}
                        value={disease.name}
                        name="diseases-1"
                        disabled={this.isInputDisabled(
                          disease.name,
                          "diseases-1"
                        )}
                        ref={this.state.myRefArr[i]}
                      />
                      <label htmlFor={"diseases1Names" + i}>
                        {disease.text}
                      </label>
                    </div>
                  )
                })}
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("diseases-1") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 10 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>
                Have you had any of the following respiratory conditions?
              </h4>
              <div className="checkbox-container">
                {formSchema["diseases2Names"].map((symptom, i) => {
                  return (
                    <div className="checkbox-div">
                      <input
                        type="checkbox"
                        key={"diseases2Names" + i}
                        id={"diseases2Names" + i}
                        value={symptom.name}
                        name="diseases-2"
                        disabled={this.isInputDisabled(
                          symptom.name,
                          "diseases-2"
                        )}
                        ref={this.state.myRefArr[i]}
                      />
                      <label htmlFor={"diseases2Names" + i}>
                        {symptom.text}
                      </label>
                    </div>
                  )
                })}
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("diseases-2") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 11 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>
                Have you had any of the following chronic medical problems?
              </h4>
              <div className="checkbox-container">
                {formSchema["diseases3Names"].map((disease, i) => {
                  return (
                    <div className="checkbox-div">
                      <input
                        type="checkbox"
                        key={"diseases3Names" + i}
                        id={"diseases3Names" + i}
                        value={disease.name}
                        name="diseases-3"
                        disabled={this.isInputDisabled(
                          disease.name,
                          "diseases-3"
                        )}
                        ref={this.state.myRefArr[i]}
                      />
                      <label htmlFor={"diseases3Names" + i}>
                        {disease.text}
                      </label>
                    </div>
                  )
                })}
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("diseases-3") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 12 &&
          <div className="question">
            <Section aria-live="polite">
              <h4>
                Do you take any of the following medications?
              </h4>
              <div className="checkbox-container">
                {formSchema["medications1Names"].map((medication, i) => {
                  return (
                    <div className="checkbox-div">
                      <input
                        type="checkbox"
                        key={"medications-1" + i}
                        id={"medications-1" + i}
                        value={medication.name}
                        name="medications-1"
                        disabled={this.isInputDisabled(
                          medication.name,
                          "medications-1"
                        )}
                        ref={this.state.myRefArr[i]}
                      />
                      <label htmlFor={"medications-1" + i}>
                        {medication.text}
                      </label>
                    </div>
                  )
                })}
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.isQuestionAnswered("medications-1") ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 13 &&
          <div className="question" aria-live="polite">
            <Section className="personal-details">
              <h4>Enter your details:</h4>
              <div sx={{ mb: 3 }}>
                <label>Age</label>
                <input label="Age" name="age" type="text" ref={this.state.myRefArr[0]}/>
              </div>
              <div sx={{ mb: 3 }}>
                <label>Gender (optional)</label>
                <select
                  name="gender"
                  placeholder="Gender"
                  onChange={this.onChangeAnswer}
                  ref={this.state.myRefArr[1]}
                >
                  <option key="gender0" value={null}>
                    --Please choose an option--
                  </option>
                  <option key="gender1" value="male">
                    Male
                  </option>
                  <option key="gender2" value="female">
                    Female
                  </option>
                  <option key="gender3" value="other">
                    Other
                  </option>
                </select>
              </div>
              <div sx={{ mb: 3 }}>
                <label>Your First Name (optional)</label>
                <input label="First Name" name="first_name" type="text" ref={this.state.myRefArr[2]}/>
              </div>
              <div sx={{ mb: 3 }}>
                <label>Your Last Name (optional)</label>
                <input label="Last Name" name="last_name" type="text" ref={this.state.myRefArr[3]}/>
              </div>
              <div sx={{ mb: 3 }}>
                <label>Your Phone Number (optional)</label>
                <div className="flex">
                  <div id="country_phone_input">
                    <input
                      label="Your Country Code"
                      name="country_phone"
                      type="text"
                      placeholder="+1"
                      autoComplete="off"
                      ref={this.state.myRefArr[4]}
                    />
                  </div>
                  <div id="phone_input">
                    <input
                      label="Your Phone"
                      name="phone"
                      type="text"
                      placeholder="Phone number..."
                      ref={this.state.myRefArr[5]}
                    />
                  </div>
                </div>
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className={this.areMandatoryQuestionsAnswered(this.state.answers) ? "next-btn" : "next-btn hidden"}
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 14 &&
          <div className="question" aria-live="polite">
            <Section>
              <div className="optional">
                <h4>
                  Enter your Doctor's email and we will contact them
                  (Recommended)
                </h4>
              </div>
              <div sx={{ mb: 3 }}>
                <label>Doctor's Email</label>
                <input
                  label="Doctor's Email"
                  name="doctor_email"
                  type="text"
                  placeholder="Doctor or Medical Practice email"
                  ref={this.state.myRefArr[0]}
                />
              </div>
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <div
                className="next-btn"
                onClick={() => this.changeQuestion('forward')}
              >
                <img src={ rightArrow } alt="next" />
              </div>
            </div>
          </div>
          }
          {this.state.question === 15 &&
          <div className="question" aria-live="polite">
            <Section className="personal-details">
              <div sx={{ mb: 3 }}>
                <h4>Find a Hospital (U.S. users only)</h4>
                <LocationSearchInput
                  onChange={this.updateLocation}
                  myRef={this.state.myRefArr[0]}
                ></LocationSearchInput>
              </div>
              {this.state.areHospitalsLoading && <Loader />}
              {this.state.hospitals && (
                <form
                  onChange={e => {
                    this.setState({
                      answers: {
                        ...this.state.answers,
                        hospital: this.getHospitalForId(e.target.value)
                      }
                    })
                  }}
                >
                  <label>Select a Hospital</label>
                  <select name="hospitals" id="hospital-select">
                    {this.state.answers.hospital ?
                    <option key="hospital0" value={this.state.answers.hospital.id}>
                      {this.displayHospital(this.state.answers.hospital)}
                    </option>
                    :
                    <option key="hospital0" value={null}>
                      --Please choose an option--
                    </option>
                    }
                    {this.state.hospitals.filter(hospital => hospital !== this.state.answers.hospital).map((hospital, i) => {
                      return (
                        <option key={i} value={hospital.id} id={this.displayHospital(hospital)}>
                          {this.displayHospital(hospital)}
                        </option>
                      )
                    })}
                  </select>
                </form>
              )}
              {/*!this.state.hospitals && (
                <select name="hospitals" id="hospital-select" ref={this.state.myRefArr[1]}>
                  <option key="hospital0" value={null}>
                    -- Try Searching --
                  </option>
                </select>
              )*/}
            </Section>
            <div className="change-question-container flex space-between">
              <div
                className={this.state.question > 1 ? "back-btn" : "back-btn hidden"}
                onClick={() => this.changeQuestion('backward')}
              >
                <img src={ leftArrow } alt="back" />
              </div>
              <button className="submit-btn" type="submit">
                Submit
              </button>
            </div>
          </div>
          }
        </form>
      </Fragment>
    )
    return (
      <div
        className="survey-page app-page"
        style={{minHeight: `${innerHeight - 9 * fontSize}px`}}
      >
        {((this.state.answers["symptoms-1"] === "yes" &&
          this.state.emergencyModal) ||
          this.state.validationModal ||
          this.state.startOverModal) && <div className="gray-screen"></div>}
        {this.state.answers["symptoms-1"] === "yes" &&
          this.state.emergencyModal && (
            <EmergencyModal
              key="EmergencyModal"
              country={this.state.answers.country}
              toggleModal={this.toggleModal}
            />
          )}
        {this.state.validationModal && (
          <ValidationModal
            key="ValidationModal"
            toggleModal={this.toggleModal}
          />
        )}
        {this.state.startOverModal &&
          <StartOverModal
            changePage={this.props.changePage}
            toggleModal={this.toggleModal}
          />
        }
        {Basic()}
      </div>
    )
  }
}

export default Survey
