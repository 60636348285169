/** @jsx jsx */
import { jsx } from "theme-ui";

function Radio(props) {
  const { children, label, ...rest } = props;
  return (
    <label
      className="radio-btn"
      sx={{
        display: "flex",
        alignItems: "center",
        border: "solid 1px #bbb",
        py: "4px",
        px: 20,
        borderRadius: 999,
        fontSize: 18,
        mr: 3,
        cursor: "pointer",
        "& > span": {
          fontWeight: 500
        },
        "& > input": {
          minWidth: "1rem",
          minHeight: "1rem"
        }
      }}
      {...rest}
    >
      {children}
      <span>{label}</span>
    </label>
  );
}

export default Radio;
