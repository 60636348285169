import React, { Component, Fragment } from "react"

import Landing from './Landing'
import Page1 from "./Page1"
import Page2 from "./Page2"
import Social from "./Social"

import firebase from "firebase/app"
import "firebase/auth"

var firebaseConfig = {
  apiKey: "AIzaSyArEdqWD-37vJ9SRqMRphXmBu9ZN-kdrns",
  authDomain: "lazarus-covid19.firebaseapp.com",
  databaseURL: "https://lazarus-covid19.firebaseio.com",
  projectId: "lazarus-covid19",
  storageBucket: "lazarus-covid19.appspot.com",
  messagingSenderId: "772265187471",
  appId: "1:772265187471:web:6543f82f6560615f09b8fa",
  measurementId: "G-ZJW80RMTBN"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSurveyAppShowing: false,
      emailModal: false,
      social: false,
      slideInSocial: false,
      url: 'https://us-central1-lazarus-covid19.cloudfunctions.net/squirrelbuscactuseucalyptusmoisture?DocEmail=',
      // url:
      //   "https://us-central1-lazarus-covid19.cloudfunctions.net/addPhoneNumber",
      selection1: false, // Medical professional
      selection2: false, // Take test
      selection3: false, // Just want to help
      submitted: false,
      selection: null,
      // country_code: "",
      physicianEmail: null,
      pages: {
        page1: true,
        page2: false
      }
    }
  }

  componentDidMount() {
    const body = document.getElementsByTagName('body')[0]
    const root = document.getElementById('root')
    body.style.height = `${window.innerHeight}px`
    root.style.height = `${window.innerHeight}px`
    window.addEventListener('resize', () => {
      // We execute the same script as before
      root.style.height = `${window.innerHeight}px`
      root.style.height = `${window.innerHeight}px`
    })
  }

  scrollToBottom = () => {
    const landingContent = document.getElementById('landing-content')
    landingContent.scrollTo({
      left: 0,
      top: landingContent.scrollHeight,
      behavior: "smooth"
    })
  }

  toggleSurvey = () => {
    this.setState({ isSurveyAppShowing: !this.state.isSurveyAppShowing })
  }

  changePage = pageToRender => {
    const pages = Object.keys(this.state.pages)
    const newState = {}
    pages.forEach(page => {
      if (page === pageToRender) {
        newState[page] = true
      } else {
        newState[page] = false
      }
    })
    this.setState({ ...this.state, pages: { ...newState } })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  toggleModal = (modal) => {
    this.setState({ [modal] : !this.state[modal] })
  }

  handleSelect = selection => {
    // this.scrollToBottom()
    if (selection === 'selection1') {
      this.setState({selection1: true, emailModal: true})
    } else {
      this.setState({ selection1: false })
      this.setState({ selection2: false })
      this.setState({ selection3: false })
      this.setState({ [selection]: true })
    }

    // scrolling for input under options
    // if ((window.innerWidth <= 600) && document.getElementById('interactive') && selection !== 'selection2') {
    //   // document.getElementById('interactive').scrollIntoView(true)
    //   // document.getElementById('root').scrollTop -= 96;
    //   // document.getElementById('options').scrollIntoView({ behavior: 'smooth' })
    //   // document.getElementById('root').scrollTop -= 96;
    // } else {
    //   document.getElementById('root').scrollTo({
    //     left: 0,
    //     top: 0,
    //     behavior: "smooth"
    //   })
    // }
  }

  handleSubmit = () => {
    const url = this.state.url + this.state.physicianEmail
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        this.setState({ submitted: true })
      })
      .catch((err) => alert('Error: please try again later'))
  }
      

  toggleSocial = () => {
    this.setState({ social: true, slideInSocial: !this.state.slideInSocial })
  }

  render() {
    const { pages, social, slideInSocial } = this.state
    return (
      <div className="main">
        {pages.page1 && (
          <Landing
            changePage={this.changePage}
            handleChange={this.handleChange}
            handleSelect={this.handleSelect}
            handleSubmit={this.handleSubmit}
            toggleSocial={this.toggleSocial}
            toggleSurvey={this.toggleSurvey}
            toggleModal={this.toggleModal}
            physicianEmail={this.state.physicianEmail}
            isSurveyAppShowing={this.state.isSurveyAppShowing}
            emailModal={this.state.emailModal}
            submitted={this.state.submitted}
            selection1={this.state.selection1}
            selection2={this.state.selection2}
            selection3={this.state.selection3}
          />
        )}
        {pages.page2 && <Page2 changePage={this.changePage} />}
        <footer className="copyright">
          <p
            className="underline text-align-right big-link"
            onClick={() => {
              this.scrollToBottom()
            }}
          >
            Legal
          </p>
          <p className="text-align-right">&#169; Lazarus 2020 All rights reserved.</p>
        </footer>
        {social && (
          <Fragment>
            <Social
              toggleSocial={this.toggleSocial}
              slideInSocial={this.state.slideInSocial}
            />
          </Fragment>
        )}
        {slideInSocial && (
          <div className="gray-screen" onClick={this.toggleSocial}></div>
        )}
      </div>
    )
  }
}

export default App
