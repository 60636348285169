/** @jsx jsx */
import { jsx, Grid, Button } from "theme-ui"
import React, { Component } from "react"
import FormHeading from "./form-heading"
class EmergencyModal extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {}
  }

  call = number => {
    window.open("tel:" + number, "_self")
  }

  getCountryPhone = () => {
    if (this.props.country && this.props.country == "AU") {
      return "000"
    } else if (this.props.country && this.props.country == "GB") {
      return "999"
    } else {
      return "911"
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div className="modal center">
        <FormHeading className="text-align-center" sx={{ textAlign: "center" }}>
          These symptoms may indicate a medical emergency.
        </FormHeading>
        <p className="text-align-center">
          If you are experiencing any of these symptoms, please call&nbsp;
          <a href={"tel:" + this.getCountryPhone()}>{this.getCountryPhone()}</a>
          &nbsp;or&nbsp;<a href="tel:112">112</a>&nbsp;(international).
        </p>
        <div className="modal-btn-container flex space between">
          <div
            className="btn-div"
            onClick={() => this.props.toggleModal("emergencyModal")}
          >
            <p>Close</p>
          </div>
          <div
            className="btn-div"
            id="red-btn"
            onClick={() => this.call(this.getCountryPhone())}
          >
            <p>Call</p>
          </div>
        </div>
      </div>
    )
  }
}

export default EmergencyModal
