export default {
  colors: {
    text: "#222",
    background: "#fff",
    primary: "#07c",
    secondary: "#05a",
    accent: "#CDF7E2",
    muted: "#e5e5e5"
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "system-ui, sans-serif",
    monospace: "Menlo, monospace"
  },
  grids: {
    radios: {
      gap: 20
    }
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  buttons: {
    primary: {
      fontSize: 20,
      color: "text",
      bg: "accent",
      "&:hover": {
        bg: "000",
        color: "#fff"
      }
    },
    secondary: {
      color: "text",
      bg: "#f9f9f9",
      "&:hover": {
        bg: "#c5c5c5",
        color: "text"
      }
    }
  }
};
