import React, { Component, Fragment } from "react"
import { ThemeProvider } from "theme-ui"

import Login from "./Login"
import Survey from "./Survey"
import Results from "./Results"
import request from "request"

import theme from "../theme"

class SurveyApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      decision: null,
      haveResultsBeenPosted: false,
      isRequestStarted: false,
      pages: {
        loginPage: true,
        surveyPage: false,
        resultsPage: false,
        extraPage: false,
        userID: null,
        mychart_id: null
      }
      // From landing page
    }
  }

  changePage = pageToRender => {
    const pages = Object.keys(this.state.pages)
    const newState = {}
    pages.forEach(page => {
      if (page === pageToRender) {
        newState[page] = true
      } else {
        newState[page] = false
      }
    })
    this.setState({
      ...this.state,
      pages: {
        ...newState
      }
    })
  }

  changeUID = (uid, mychart_id, email) => {
    this.setState({
      userID: uid,
      mychart_id: mychart_id
    })
    if (email !== "none") {
      this.setState({
        email: email
      })
    }
  }

  submitUserResponse = data => {
    delete data[""] // remove empty key "" this breaks the cloud function

    if (this.state.userID) {
      data["userID"] = this.state.userID
      data["mychart_id"] = this.state.mychart_id
    }

    if (this.state.email) {
      data["email"] = this.state.email
    }

    request.post(
      "https://us-central1-lazarus-covid19.cloudfunctions.net/function-1",
      {
        body: JSON.stringify(data)
      },
      (error, res, body) => {
        if (error) {
          console.error(error)
          this.setState({ message: "Error: please try again later" })
          return
        }
        console.log(`statusCode: ${res.statusCode}`)
        const decision = JSON.parse(body)
        this.setState({
          userResponse: data,
          message: decision.message,
          risk: decision.risk
        })
      }
    )
  }

  componentDidUpdate() {
    if (this.state.message && !this.state.haveResultsBeenPosted && !this.state.isRequestStarted) {
      this.setState({
        isRequestStarted: true
      })
      request.post(
        "https://us-central1-lazarus-covid19.cloudfunctions.net/submitSurvey",
        {
          json: {
            ...this.state.userResponse,
            decision: this.state.decision,
            risk: this.state.risk,
            message: this.state.message
          }
        },
        (error, res, body) => {
          if (error) {
            this.setState({
              isRequestStarted: false
            })
            console.error(error)
            return
          }
          this.setState({ haveResultsBeenPosted: true })
          console.log(`statusCode: ${res.statusCode}`)
        }
      )
    }
  }

  render() {
    const { pages } = this.state
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          {pages.loginPage && (
            <Login setUser={this.changeUID} changePage={this.changePage} />
          )}
          {pages.surveyPage && (
            <Survey
              changePage={this.changePage}
              submitUserResponse={this.submitUserResponse}
            />
          )}
          {pages.resultsPage && (
            <Results
              changePage={this.changePage}
              isLoading={this.state.isLoading}
              message={this.state.message}
            />
          )}
        </Fragment>
      </ThemeProvider>
    )
  }
}

export default SurveyApp