import countries from "./countries.json"

const symptoms3 = [
  {
    name: "none",
    text: "None"
  },
  {
    name: "fever",
    text: "Fever"
  },
  {
    name: "fatigue",
    text: "Fatigue"
  },
  {
    name: "cough",
    text: "Cough"
  },
  {
    name: "sneezing",
    text: "Sneezing"
  },
  {
    name: "aches-pains",
    text: "Aches and Pains"
  },
  {
    name: "stuffy-nose",
    text: "Stuffy nose"
  },
  {
    name: "runny-nose",
    text: "Runny nose"
  },
  {
    name: "sore-throat",
    text: "Sore throat"
  },
  {
    name: "diarrhea",
    text: "Diarrhea"
  },
  {
    name: "headaches",
    text: "Headaches"
  },
  {
    name: "sob",
    text: "Shortness of Breath"
  },
  {
    name: "vomiting",
    text: "Vomiting"
  },
  {
    name: "loss-smell",
    text: "Loss of sense of smell"
  },
  {
    name: "loss-taste",
    text: "Loss of sense of taste"
  }
]

const diseases1 = [
  {
    name: "none",
    text: "None"
  },
  {
    name: "cancer",
    text: "Cancer"
  },
  {
    name: "leukemia",
    text: "Leukemia"
  },
  {
    name: "hiv",
    text: "HIV / AIDS"
  },
  {
    name: "auto-immune",
    text: "Other auto immune disorders"
  },
  {
    name: "organ-transplant",
    text: "Organ Transplant"
  },
  {
    name: "unsure",
    text: "Unsure"
  }
]


const diseases2 = [
  {
    name: "none",
    text: "None"
  },
  {
    name: "copd",
    text: "Chronic obstructive pulmonary disease (COPD)"
  },
  {
    name: "asthma",
    text: "Asthma"
  },
  {
    name: "pneumonia",
    text: "Pneumonia"
  },
  {
    name: "respiratory",
    text: "Respiratory disease"
  },
  {
    name: "unsure",
    text: "Unsure"
  }
]

const diseases3 = [
  {
    name: "none",
    text: "None"
  },
  {
    name: "diabetes",
    text: "Diabetes"
  },
  {
    name: "hypertension",
    text: "Hypertension"
  },
  {
    name: "cardiovascular",
    text: "Cardiovascular disease"
  },
  {
    name: "unsure",
    text: "Unsure"
  }
]

const medications1 = [
  {
    name: "none",
    text: "None"
  },
  {
    name: "immunosuppressants",
    text: "Immunosuppressants (Immune weakening medications)"
  },
  {
    name: "chronic-corticosteroid",
    text: "Chronic corticosteroid use (like prednisone)"
  },
  {
    name: "ace2-inhibitor",
    text: "ACE2 inhibitors (Ramipril, etc.)"
  },
  {
    name: "ibuprofen",
    text: "Ibuprofen / Advil"
  },
  {
    name: "other-nsaids",
    text: "Other NSAIDs (Asprin, Aleve, etc.)"
  },
  {
    name: "unsure",
    text: "Unsure"
  }
]

const formSchema = {
  countryNames: countries,
  symptoms3Names: symptoms3,
  diseases1Names: diseases1,
  diseases2Names: diseases2,
  diseases3Names: diseases3,
  medications1Names: medications1
}

export default formSchema
