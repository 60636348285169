/** @jsx jsx */
import { jsx, Grid, Button } from "theme-ui";
import React, { Component } from "react";

function FormHeading(props) {
  const { children, ...rest } = props;
  return (
    <h4 sx={{ fontSize: [18, 20, 22, 24], fontWeight: 600 }} {...rest}>
      {children}
    </h4>
  );
}

export default FormHeading;
