import React, { Component } from "react"
import Loader from "./Loader"

class Results extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: null
    }
  }

  render() {
    const innerHeight = window.innerHeight
    const parentElement = document.getElementById('root')
    const fontSize = parseFloat(getComputedStyle(parentElement).fontSize)
    const landingHeight = innerHeight - 5 * fontSize
    return (
      <div className="app-page" style={{minHeight: `${innerHeight - 9 * fontSize}px`}}>
        {!this.props.message && (
          <div className="center">
            <Loader />
          </div>
        )}
        {this.props.message && (
          <div id="results-container" className="results-container" style={{maxHeight: `${landingHeight}px`, minHeight: `${innerHeight - 9 * fontSize}px`}}>
            <p> {this.props.message} </p>
            {/* <div id="legal_text">
              <p>
                <span>
                  <b>Please Note:</b>
                </span>
                <br />
                The COVID-19 Assessment Tool for Community Health provides
                general information based on interim clinical guidance developed
                by the Centers for Disease Control and Prevention (CDC). This
                tool
                <span>
                  <b> DOES NOT </b>
                </span>
                provide diagnosis or treatment of COVID-19. It{" "}
                <span>
                  <b> DOES NOT </b>
                </span>
                take into consideration your complete current condition and
                medical history. It
                <span>
                  <b> SHOULD NOT </b>
                </span>
                be used as a substitute for the advice of an appropriately
                qualified and licensed physician or other health care provider.
                Always consult a medical professional for serious symptoms.
              </p>
            </div> */}
          </div>
        )}
      </div>
    )
  }
}

export default Results
