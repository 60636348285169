import React, { Component } from "react"

class Page2 extends Component {
  constructor(props) {
    super(props)
    this.section1 = React.createRef()
    this.section2 = React.createRef()
    this.section3 = React.createRef()
    this.section4 = React.createRef()
    this.state = {
    }
  }

  render() {
    return (
      <div>
        <div>
          <div className="scroll-section" ref={this.props.myRefArr[0]}>
            <div className="text-box width-75">
              <h2>What is this?</h2>
              <p>
                Patient testing for COVID-19 has created a global bottleneck in
                delivering care to patients that need it the most. <u className="underline">
                Our mission is to reduce this bottleneck to enable appropriate
                patients to receive testing and care to save lives. We’ve developed a
                triage tool to allow patients to provide information on their
                symptoms, prescreen based on established CDC Guidelines, and engage
                with their providers to improve patient flow and access for COVID
                testing
              </u>
              </p>
              <p>
              Your data will only be used to inform your healthcare provider and conduct research on COVID-19. Your information may also be sent to other healthcare providers in your state, if your provider cannot accommodate you. If you attach your email or phone number, they may be used by your provider to contact you. If your data is used for research, your data shall be anonymized and only your de-identified data will be used by researchers. Your data will not be sold. Your data will not be rented. Your data is your data. We just want to help.
              </p>
            </div>
          </div>
          <div className="scroll-section" ref={this.props.myRefArr[1]}>
            <div className="text-box">
            <h2>How does it work?</h2>
            <div className="step-columns">
              <div className="flex-column">
                <h4>Step 1</h4>
                <p>
                  In under 2 minutes, patients can fill out a brief form listing their
                  symptoms and recent activities, optionally linking to MyChart in the
                  USA.
                </p>
              </div>
              <div className="flex-column">
                <h4>Step 2</h4>
                <p>
                  We perform preliminary screening based on <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/clinical-criteria.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CDC’s Coronavirus Guidelines
                  </a>
                </p>
              </div>
              <div className="flex-column">
                <h4>Step 3</h4>
                <p>
                  Patient's Doctor, Medical Provider or Hospital is emailed to sign-up
                  to receive access
                </p>
              </div>
              <div className="flex-column">
                <h4>Step 4</h4>
                <p>
                  Providers will receive their patient's information and are able to
                  triage remotely. Patients that require urgent care or testing can be
                  appropriately scheduled
                </p>
              </div>
              </div>
            </div>
          </div>
          <div className="scroll-section" ref={this.props.myRefArr[2]}>
            <div className="text-box">
              <h2>Who are we?</h2>
              <p>
                We are a global group of Data Scientists, Software Engineers, and
                Designers with experience in the MedTech space.
              </p>
            </div>
          </div>
          <div className="scroll-section" ref={this.props.myRefArr[3]}>
            <div className="text-box">
              <h2>Legal</h2>
              <p>
                The COVID-19 Assessment Tool for Community Health provides general
                information based on interim clinical guidance developed by the
                Centers for Disease Control and Prevention (CDC). This tool
                <span>
                  <b> DOES NOT </b>
                </span>
                provide diagnosis or treatment of COVID-19. It
                <span>
                  <b> DOES NOT </b>
                </span>
                take into consideration your complete current condition and medical
                history. It
                <span>
                  <b> SHOULD NOT </b>
                </span>
                be used as a substitute for the advice of an appropriately qualified
                and licensed physician or other health care provider. Always consult a
                medical professional for serious symptoms. Any partners that distribute
                this tool, including insurers, hospitals, corporations, and the Batey 
                Relief Alliance, are absolved of any liability in the course of assisting
                our group in sharing this assessment with others.
              </p>
              <a className="contact-link" href="mailto:covid@lazarusai.com">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Page2