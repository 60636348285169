import React, { Component, Fragment } from "react"
import SurveyApp from './SurveyApp'
import Page2 from './Page2'
import upArrow from '../upArrow.svg'
import EmailModal from './EmailModal'

class Landing extends Component {
  constructor(props) {
    super(props)
    this.landingRef = React.createRef()
    this.state = {
      isSurveyAppShowing: false,
      myRefArr: [React.createRef(), React.createRef(), React.createRef(), React.createRef()],
    }
  }

  componentDidMount() {
    if (window.location.search !== "") {
      this.props.handleSelect("selection2")
      this.props.toggleSurvey()
    } else {
    }
    this.setState({scrollTop: this.landingRef.current.scrollTop})
    let self = this
    this.landingRef.current.addEventListener('scroll', () => {
      self.setState({scrollTop: self.landingRef.current.scrollTop})
    })
  }

  getSelectedClass(bool) {
    return bool ? "option-selected" : ""
  }

  scrollIntoView = (ref) => {
    const root = document.getElementById('landing-content')
    const scrollTop = root.scrollTop
    const fontSize = parseFloat(getComputedStyle(root).fontSize)

    const top = ref.current.getBoundingClientRect().top - (3 * fontSize) + scrollTop
    root.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth'
    })
  }

  scrollToTop = () => {
    this.landingRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  render() {
    const innerHeight = window.innerHeight
    const parentElement = document.getElementById('root')
    const fontSize = parseFloat(getComputedStyle(parentElement).fontSize)
    const landingHeight = innerHeight - 6 * fontSize
    // const landingContent = document.getElementById('landing-content')

    return (
      <Fragment>
        <div className="page">
          <nav>
            <div>
              <div
                className="catch-div flex justify-center align-center"
                onClick={this.scrollToTop}
              >
                <p className="margin-0">CATCH</p>
              </div>
              {this.state.scrollTop > (6 * fontSize) &&
                <Fragment>
                  <p id="back-to-top" className="big-link" onClick={this.scrollToTop}>Back to top &#8593;</p>
                  <img src={upArrow} alt="top" id="back-to-top-mobile" onClick={this.scrollToTop} />
                </Fragment>
              }
              <div>
                <h4 onClick={() => this.scrollIntoView(this.state.myRefArr[0])} className="big-link">
                  What is this?
                </h4>
                <h4 onClick={() => this.scrollIntoView(this.state.myRefArr[1])} className="big-link">
                  How does it work?
                </h4>
              </div>
            </div>
          </nav>
          <div
            id="landing-content"
            className="landing-content"
            style={{ height: `${landingHeight}px` }}
            ref={this.landingRef}
          >
            {this.props.isSurveyAppShowing ?
              <SurveyApp />
            :
            <div className="page1-container">
              <div className="a flex-column">
                <h2>COVID-19 Assessment Tool for Community Health</h2>
                <p>Self-screening to protect both you and your community</p>
              </div>
              <div className="b flex-column flex-end align-end">
                <div
                  className="share-btn flex justify-center align-center"
                  onClick={this.props.toggleSocial}
                >
                  <p>Share</p>
                </div>
              </div>
              <div className="c flex-column justify-center">
                {this.props.submitted ? (
                  <div className="post-submit-container">
                    <p className="bold">Registration complete.</p>
                    <p>
                      Thank you for joining CATCH - we will send you an access-link once you are verified
                    </p>
                  </div>
                ) : (
                  <Fragment>
                    <div id="options" className="options">
                      <div
                        className={
                          "option flex justify-center align-center " +
                          this.getSelectedClass(this.props.selection2)
                        }
                        onClick={() => {
                          this.props.handleSelect("selection2")
                          this.props.toggleSurvey()
                        }}
                      >
                        <p>Screen for COVID-19</p>
                      </div>
                      <div
                        className={
                          "option flex justify-center align-center " +
                          this.getSelectedClass(this.props.selection1)
                        }
                        onClick={() => this.props.handleSelect("selection1")}
                      >
                        <p>Physician Dashboard</p>
                      </div>
                      {/* <div
                        className={
                          "option flex justify-center align-center " +
                          this.getSelectedClass(this.props.selection3)
                        }
                        onClick={() => this.props.handleSelect("selection3")}
                      >
                        <p>I just want to help</p>
                      </div> */}
                    </div>
                    <div id="interactive" className="interactive">
                      {(this.props.selection1 && this.props.emailModal) && ( // remove selection 2 when you have a link
                        <Fragment>
                          <EmailModal
                            handleChange={this.props.handleChange}
                            handleSubmit={this.props.handleSubmit}
                            toggleModal={this.props.toggleModal}
                          />
                          <div className="gray-screen"></div>
                        </Fragment>
                        // <div className="country-container">
                        //   {/* <input
                        //     name="country_code"
                        //     className="country-input phone-input"
                        //     onChange={this.props.handleChange}
                        //     placeholder="+1"
                        //   /> */}
                        //   <input
                        //     className="phone-input"
                        //     name="physicianEmail"
                        //     onChange={this.props.handleChange}
                        //     placeholder="Email..."
                        //   />
                        //   {this.props.physicianEmail && (
                        //     <p
                        //       className="margin-0 submit-p"
                        //       onClick={this.props.handleSubmit}
                        //     >
                        //       Submit &#10230;
                        //     </p>
                        //   )}
                        // </div>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
            }
            <Page2
              scrollIntoView={this.scrollIntoView}
              myRefArr={this.state.myRefArr}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Landing