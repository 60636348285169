import React from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from '../../src';

const isObject = val => {
  return typeof val === "object" && val !== null;
};

export const classnames = (...args) => {
  const classes = [];
  args.forEach(arg => {
    if (typeof arg === "string") {
      classes.push(arg);
    } else if (isObject(arg)) {
      Object.keys(arg).forEach(key => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    } else {
      throw new Error(
        "`classnames` only accepts string or object as arguments"
      );
    }
  });

  return classes.join(" ");
};

function getLatLong(googleResponse) {
  try {
    const lat = googleResponse[0].geometry.location.lat();
    const lon = googleResponse[0].geometry.location.lng();
    return [lat, lon];
  } catch {
    return [null, null];
  }
}
function getAddressDetails(googleResponse) {
  var result = {
    country: null,
    postcode: null,
    area: null,
    local_area: null,
    lat: null,
    lon: null
  };

  if (googleResponse.length > 0) {
    const [lat, lon] = getLatLong(googleResponse);
    result["lat"] = lat;
    result["lon"] = lon;
    for (var part of googleResponse[0]["address_components"]) {
      const short_name = part["short_name"];
      const types = part["types"];
      for (const type of types) {
        if (type === "country") {
          result["country"] = short_name;
        } else if (type === "postal_code") {
          // zip code
          result["postcode"] = short_name;
        } else if (type === "administrative_area_level_1") {
          // state
          result["area"] = short_name;
        } else if (type === "locality") {
          // suburb
          result["local_area"] = short_name;
        }
      }
    }
  }
  return result;
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      addressDetails: {},
      errorMessage: "",
      latitude: null,
      longitude: null,
      isGeocoding: false
    };
  }

  handleChange = address => {
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: ""
    });
  };

  handleSelect = selected => {
    this.setState({ isGeocoding: true, address: selected });
    geocodeByAddress(selected)
      .then(res => {
        this.setState({
          addressDetails: getAddressDetails(res)
        });
        this.props.onChange(getAddressDetails(res));
        return getLatLng(res[0]);
      })
      .then(({ lat, lng }) => {
        this.setState({
          latitude: lat,
          longitude: lng,
          isGeocoding: false
        });
      })
      .catch(error => {
        this.setState({ isGeocoding: false });
        console.log("error", error); // eslint-disable-line no-console
      });
  };

  handleCloseClick = () => {
    this.setState({
      address: "",
      latitude: null,
      longitude: null
    });
  };

  handleError = (status, clearSuggestions) => {
    console.log("Error from Google Maps API", status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };

  render() {
    const {
      address,
      errorMessage,
      latitude,
      longitude,
      isGeocoding
    } = this.state;

    return (
      <div>
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={address}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="Demo__search-bar-container">
                <div className="Demo__search-input-container">
                  <input
                    name="location_search_term"
                    {...getInputProps({
                      placeholder: "Type Location or Hospital Name...",
                      className: "Demo__search-input"
                    })}
                    ref={this.props.myRef}
                  />
                  {this.state.address.length > 0 && (
                    <button
                      className="Demo__clear-button"
                      onClick={this.handleCloseClick}
                    >
                      x
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className="Demo__autocomplete-container">
                    {suggestions.map(suggestion => {
                      const className = classnames("Demo__suggestion-item", {
                        "Demo__suggestion-item--active": suggestion.active
                      });

                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                    <div className="Demo__dropdown-footer"></div>
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
        {errorMessage.length > 0 && (
          <div className="Demo__error-message">{this.state.errorMessage}</div>
        )}
      </div>
    );
  }
}

export default SearchBar;
