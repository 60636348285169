/** @jsx jsx */
import { jsx, Grid, Button } from "theme-ui"
import React, { Component, Fragment } from "react"
import firebase from "firebase"
import "firebase/auth"
import queryString from "query-string"
import request from "request"
import Modal from "./Modal"
import Social from "./Social"

import Loader from "./Loader"

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      needsEmail: false,
      emailSent: false,
      emailError: false,
      hasChoiceBeenMade: false,
      myChartOption: false,
      emailOption: false,
      anonymousOption: false,
      loginModal: null,
      social: false
    }
  }

  componentDidMount() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      //retrieve stored email
      let email = window.localStorage.getItem("emailForSignIn")
      // If missing email, prompt user for it
      if (!email) {
        this.setState({
          isLoading: false,
          needsEmail: true,
          hasChoiceBeenMade: true,
          emailOption: true
        })
      } else {
        // Signin user and remove the email localStorage
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(result => {
            window.localStorage.removeItem("emailForSignIn")
            this.props.setUser(
              firebase.auth().currentUser.uid,
              "none",
              firebase.auth().currentUser.email
            )
            this.props.changePage("surveyPage")
          })
          .catch(err => {
            alert("There was an error, this link may have expired.")
            console.log(err)
            this.errorMessage = err.message
          })
      }
    } else if (queryString.parse(window.location.search).patient_id) {
      request.post(
        "https://us-central1-lazarus-covid19.cloudfunctions.net/checkUserStatus",
        {
          json: {
            patientID: queryString.parse(window.location.search).patient_id
          }
        },
        (error, _, body) => {
          if (error) {
            console.error(error)
            return
          }
          if (body.exists) {
            this.props.setUser(
              queryString.parse(window.location.search).patient_id,
              queryString.parse(window.location.search).patient_id, //right now they are the same value
              "none"
            )
            this.props.changePage("surveyPage")
          } else {
            this.setState({
              isLoading: false
            })
          }
        }
      )
    } else {
      this.setState({
        isLoading: false
      })
    }
  }

  toggleModal = modal => {
    this.setState({
      [modal]: null
    })
  }

  onClickChoice = choice => {
    this.setState({
      [choice]: !this.state[choice],
      hasChoiceBeenMade: !this.state.hasChoiceBeenMade
    })
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  sendEmail = emailToSendTo => {
    //settings for email

    const actionCodeSettings = {
      // Your redirect URL
      url: window.location.href,
      handleCodeInApp: true
    }
    firebase
      .auth()
      .sendSignInLinkToEmail(emailToSendTo, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", emailToSendTo)
        this.setState({
          emailSent: true,
          emailError: false,
          hasChoiceBeenMade: true,
          emailOption: false
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({
          emailError: true
        })
      })
  }

  retrySignIn = emailToUse => {
    this.setState({
      isLoading: true
    })
    firebase
      .auth()
      .signInWithEmailLink(emailToUse, window.location.href)
      .then(result => {
        window.localStorage.removeItem("emailForSignIn")
        this.props.setUser(
          firebase.auth().currentUser.uid,
          "none",
          firebase.auth().currentUser.email
        )
        this.props.changePage("surveyPage")
      })
      .catch(err => {
        this.setState({
          emailError: true,
          isLoading: false
        })
      })
  }

  render() {
    const innerHeight = window.innerHeight
    const parentElement = document.getElementById('root')
    const fontSize = parseFloat(getComputedStyle(parentElement).fontSize)
    return (
      <div
        className="login-page app-page"
        style={{minHeight: `${innerHeight - 9 * fontSize}px`}}
      >
        <div className="login-page-container">
          {/*this.state.social &&
            <Fragment>
              <div className="gray-screen"></div>
              <Social toggleModal={this.toggleModal} />
            </Fragment>
          */}
          { this.state.isLoading &&
            <div className="center">
              <Loader />
            </div>
          }
          {!this.state.hasChoiceBeenMade && !this.state.isLoading && (
            <div className="btn-container flex-column space-between">
              <h1 id="covid-title" sx={{ fontSize: [24, 32, 36], mb: 10, fontWeight: 800 }}>
                COVID Assessment Tool
              </h1>
              <p className="text-align-center margin-1em">
                Take our 2 minute COVID screening tool, and we will forward your
                details to your Doctor, Medical Provider or Hospital if
                necessary.
              </p>
              <div className="vertical-btn-container flex-column space-between">
                <div className="btn-div full-width"
                  onClick={() => {
                    window.open(
                      "https://lazarus-connect-covid.firebaseapp.com/?source=single&type=covid19",
                      "_self"
                    )
                  }}
                >
                  <p>Use MyChart</p>
                </div>
                <div className="btn-div full-width" onClick={() => this.onClickChoice("emailOption")}>
                  <p>Use Email Address</p>
                </div>
              </div>
              <div
                className="anonymous-btn"
                sx={{ mt: 20 }}
                onClick={() => this.props.changePage("surveyPage")}
              >
                <p className="text-align-center link">Anonymously</p>
              </div>
            </div>
          )}
          {this.state.emailOption && (
            <form
              className="login"
              sx={{ width: "100%" }}
              onSubmit={event => {
                event.preventDefault()
                if (this.state.needsEmail) {
                  this.retrySignIn(this.state.email)
                } else {
                  this.sendEmail(this.state.email)
                }
              }}
            >
              <div sx={{ mb: 3 }}>
                <label>
                  {this.state.needsEmail
                    ? this.state.emailError
                      ? "There was an error, this link may have expired."
                      : "Please provide your email for confirmation"
                    : this.state.emailError
                    ? "There was an error, please check the email submitted."
                    : "Email"}
                </label>
                <input
                  onChange={event => this.handleChange(event)}
                  type="email"
                  name="email"
                />
              </div>

              <Grid columns={[1]} gap={20}>
                <div
                  className="btn-div full-width"
                  onClick={event => {
                    event.preventDefault()
                    if (this.state.needsEmail) {
                      this.retrySignIn(this.state.email)
                    } else {
                      this.sendEmail(this.state.email)
                    }
                  }}
                >
                  <p>Continue</p>
                </div>
                <div
                  className="btn-div full-width"
                  variant="secondary"
                  onClick={event => {
                    event.preventDefault()
                    this.setState({
                      emailOption: false,
                      needsEmail: false,
                      hasChoiceBeenMade: false,
                      emailError: false
                    })
                  }}
                >
                  <p>Back</p>
                </div>
              </Grid>
            </form>
          )}
          {this.state.emailSent && (
            <div className="btn-container flex-column space-between">
              <h1 sx={{ fontSize: [24, 32, 36], mb: 10, fontWeight: 800 }}>
                Email Sent
              </h1>
              <p sx={{ fontSize: [18, 20, 22], lineHeight: 1.4, mb: [30] }}>
                Please check your inbox to verify your email so you can save
                your results.
              </p>
              {/* <Grid columns={[null, 1]} gap={20}>
                  <Button onClick={() => 
                      this.setState({
                        hasChoiceBeenMade: false,
                        emailSent: false
                      })
                    }
                  >
                        Back
                  </Button>
                </Grid> */}
            </div>
          )}
        </div>
        {/* <div className="login-page-container">
          <Social />
        </div> */}
      </div>
    )
  }
}

export default Login
