import React, { Component } from "react"
import FormHeading from "./form-heading"

class ValidationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div className="modal center">
        <FormHeading className="text-align-center">
          Please answer all required questions
        </FormHeading>
        <button
          onClick={() => this.props.toggleModal("validationModal")}
          className="pink css-1g18898-Basic"
          type="submit"
        >
          Close
        </button>
      </div>
    )
  }
}

export default ValidationModal
