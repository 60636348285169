import React, { Component } from "react"
import FormHeading from "./form-heading"

class StartOverModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div className="modal center">
        <FormHeading className="text-align-center">
          Are you sure you want to return to the login page? None of your answers will be saved.
        </FormHeading>
        <div className="modal-btn-container flex space-between">
          <div
            onClick={() => this.props.toggleModal("startOverModal")}
            className="btn-div"
            type="submit"
          >
            <p>No</p>
          </div>
          <div
            onClick={() => this.props.changePage("loginPage")}
            className="btn-div"
            type="submit"
          >
            <p>Yes</p>
          </div>
        </div>
      </div>
    )
  }
}

export default StartOverModal